<template>
<v-container fluid class="flex-container-overflow">
  <v-row justify="center" align="center" style="height: 90vh">
    <v-col cols="12" sm="6">
      <div>
        <v-card-title class="title text-center" style="word-break: break-word;">
          <span style="margin: 0 auto;">
            {{ $t('moreInvoiceInformation.successMessage') }}
          </span>
        </v-card-title>
      </div>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
import axiosInstance from '../plugins/axios';

export default {
  name: "AddMoreInvoiceInformation",
  async mounted() {
    try {
      if (this.$route.query) {
        await axiosInstance.post('/invoices/more-received-invoice-information/', { token: this.$route.query.token, for_company: this.$route.query.for_company });
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.detail === 'Stale token for given user.') {
        return;
      }
      this.$snackbar({
        timeout: 3000,
        content: 'Error when providing data.',
        color: 'error',
      });
    }
  }
}
</script>

<style scoped>

</style>
